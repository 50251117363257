<template>
  <div id="Home">
    <ArtistList v-if="connectivityProbe && artists_.length"
      :artists="artists_" />
    <SkeletonLoader v-else :origin="origin" :connectivityProbe="connectivityProbe" />
  </div>
</template>

<script>
import SkeletonLoader from '@/components/Misc/SkeletonLoaders/Artists'
import ArtistList from '@/components/Artists/ArtistList'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    SkeletonLoader,
    ArtistList
  },
  computed: {
    ...mapGetters(['artists', 'searchMode', 'searchResults', 'connectivityProbe']),
    origin: {
      get: vm => vm.searchMode.state ? 'shelf-artists-search' : 'shelf-artists'
    },
    artists_: {
      get: vm => vm.searchMode.state && 'Artists'.includes(vm.$route.name)
        ? vm.searchResults
        : vm.artists
    }
  }
}
</script>
