<template>
    <v-dialog v-model="dialog.state" persistent max-width="320">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.info"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" text @click="dialog.state = false">Cancel</v-btn>
          <v-btn color="error darken-1" text @click="confirmAction">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ConfirmationModal',
  computed: {
    ...mapGetters(['confirmModal']),
    dialog: {
      get: vm => vm.confirmModal,
      set (val) {
        this.$store.dispatch('updateConfirmModal', { state: val })
      }
    }
  },
  methods: {
    async confirmAction () {
      const operation = this.confirmModal.operation
      const identifier = this.confirmModal.identifier
      await this.$store.dispatch(operation, identifier)
        .then(() => {
          this.dialog = false
        })
    }
  }
}
</script>
