<template>
  <v-row id="AlbumList">
    <v-col v-for="(album, key) in albums" :key="key" cols="12" md="6">
      <v-card class="albums mx-auto" outlined dark>
        <v-list-item three-line>
          <v-list-item-avatar tile size="120" color="grey">
            <img :src = album.image alt="CoverImage">
          </v-list-item-avatar>

          <v-list-item-content class="pb-0 pt-2">
            <div class="mb-0 mt-1">ALBUM</div>
            <v-list-item-title class="headline mb-1" :title="album.name">
              {{ album.name }}
              <v-chip v-if="album.manual" color="primary" x-small>manual</v-chip>
            </v-list-item-title>
            <v-list-item-subtitle>By: {{ album.artist.name }}</v-list-item-subtitle>

            <v-chip-group active-class="pink white--text" column>
              <v-chip class="my-0 mx-2" color="pink" text-color="white">
                <v-avatar left class="pink darken-4">{{ album.tracks.length }}</v-avatar>Tracks
              </v-chip>
              <v-chip class="my-0 mx-2" color="pink" text-color="pink" outlined>
                {{ `${album.listeners} Listeners` }}
                <v-icon right>mdi-music-clef-treble</v-icon>
              </v-chip>
            </v-chip-group>
          </v-list-item-content>

          <v-tooltip v-for="(operation, key) in albumOperations" :key="key" bottom>
            <template v-slot:activator="{ on, attrs }">
               <v-fade-transition>
                 <v-btn v-bind="attrs" v-on="on" class="mt-10 metronome" :color="operation.color"
                    :style="{ animationDuration: `${60 / 70}s` }"
                    fab outlined dark
                    absolute top right
                    @click="invokeMethod(operation.handler, album)">
                    <v-icon large v-text="operation.icon"></v-icon>
                  </v-btn>
                </v-fade-transition>
            </template>
            <span>{{ operation.text }}</span>
          </v-tooltip>
        </v-list-item>

        <v-card-text class="pt-0 pb-2">
          <v-card-subtitle class="pb-2 pt-1">TRACKLIST</v-card-subtitle>
          <v-data-table dense
            :headers="headers"
            :items="album.tracks"
            :page.sync="album.paginationPage"
            :items-per-page="4"
            hide-default-footer
            class="elevation-0"
            @page-count="album.paginationLength = $event"
          >
            <template v-slot:item.play="{ item }">
              <v-icon color="pink" class="play-btn"
              v-text="`mdi-${item.playing?'pause':'play'}-circle-outline`"
                @click="playTrack(item)"></v-icon>
            </template>
          </v-data-table>
          <div class="text-center pt-2" v-show="album.paginationLength > 1">
            <v-pagination circle dark color="pink"
            v-model="album.paginationPage"
            :length="album.paginationLength" ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <TrackPlayer v-if="Object.keys(nowPlaying).length" :track="nowPlaying" />
  </v-row>
</template>

<script>
import TrackPlayer from '../Misc/TrackPlayer'
export default {
  name: 'AlbumList',
  props: ['albums', 'headers', 'albumOperations'],
  data: () => {
    return {
      nowPlaying: {}
    }
  },
  components: { TrackPlayer },
  methods: {
    invokeMethod (name, args) {
      this[name](args)
    },
    addAlbum (album) {
      this.$store.dispatch('addAlbum', {
        id: album.id,
        name: album.name,
        artistId: album.artist.id,
        artistName: album.artist.name,
        manual: false
      })
    },
    removeAlbum (album) {
      const exists = this.albums.filter(albm => albm.artist.id === album.artist.id).length

      this.$store.dispatch('updateConfirmModal', {
        state: true,
        identifier: { albumId: album.id, artistId: album.artist.id },
        operation: 'removeAlbum',
        title: 'Delete Album?',
        info: `This operation is irreversible, would you like to proceed?  ${exists === 1
              ? `<br /><small style="color:#e91e63">This is the only album by
              ${album.artist.name} in your shelf, deleting it will delete the artist as well</small>` : ''}`
      })
    },
    playTrack (track) {
      this.albums.map(album => album.tracks.map(track => { track.playing = false }))

      track.playing = !track.playing
      this.nowPlaying = track
    }
  }
}
</script>
