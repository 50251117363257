<template>
  <v-row v-if="connectivityProbe && skeletonLoading.artist">
    <v-col v-for="n in 12" :key="n" text-align="center" class="px-1" cols="12" lg="2" md="3" sm="4">
      <v-skeleton-loader
        class="artists-loader"
        v-bind="attrs"
        type="avatar, sentences">
      </v-skeleton-loader>
    </v-col>
  </v-row>
  <ErrorPage v-else :origin="origin_" />
</template>

<script>
import ErrorPage from '@/components/Misc/ErrorPage'
import { mapGetters } from 'vuex'

export default {
  props: ['origin'],
  name: 'ArtistsLoader',
  components: {
    ErrorPage
  },
  data: () => {
    return {
      attrs: {
        class: 'mb-5 mx-10',
        boilerplate: false
      }
    }
  },
  computed: {
    ...mapGetters(['skeletonLoading', 'connectivityProbe']),
    origin_: {
      get: vm => vm.connectivityProbe ? vm.origin : 'connectivity'
    }
  }
}
</script>
