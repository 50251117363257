<template>
  <v-responsive max-width="450" dark>
    <v-row justify="space-around">
      <v-col cols="12" class="search-card">
        <v-dialog transition="dialog-top-transition" max-width="475px" v-model="dialog">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field prepend-inner-icon='mdi-magnify' color="pink"
              class="search-field" :label="`Search ${searchTarget}...`"
              dense readonly dark flat hide-details rounded solo outlined
              @keyup.stop="dialog = !dialog" @click.stop="dialog = !dialog"
              v-bind="attrs" v-on="on" v-show="!dialog"></v-text-field>
          </template>
          <v-card elevation="24">
            <v-card-title>{{ `Search ${searchTarget}` }}</v-card-title>
            <v-card-text class="px-15 py-5">
              <v-text-field v-model="searchKey" label="Search..." prepend-inner-icon='mdi-magnify' color="pink"
                :append-icon="!disabled ? 'mdi-send-outline':null"
                @click:append="searchData"
                dense dark flat hide-details clearable rounded solo outlined>
              </v-text-field>
              <template v-if="'Home'.includes($route.name)">
                <div class="grey--text text--lighten-1 text-body-2 mt-5">Use these filters to expand your search</div>
                <v-row>
                  <v-col v-for="(filter, key) in searchFilters" :key="key" cols="12" sm="6" md="6">
                    <v-checkbox color="pink" v-model="filter.model" :label="filter.label"></v-checkbox>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn plain color="grey" @click="dialog = false">Cancel</v-btn>
              <v-btn plain color="pink" @click="searchData" :disabled="disabled">Search</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-responsive>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Search',
  computed: {
    ...mapGetters(['searchBarVisibility', 'searchFilters', 'searchQuery']),
    searchKey: {
      get: vm => vm.searchQuery,
      set (val) {
        this.$store.dispatch('updateSearchQuery', { key: val })
      }
    },
    dialog: {
      get: vm => !vm.searchBarVisibility,
      set (val) {
        this.$store.dispatch('updateSearchBarVisibility', !val)
      }
    },
    searchTarget: {
      get: vm => 'Home'.includes(vm.$route.name)
        ? 'Shelf Albums'
        : 'Artists'.includes(vm.$route.name)
          ? 'Shelf Artists'
          : 'Last.Fm Albums'
    },
    disabled: {
      get: vm => 'Home'.includes(vm.$route.name)
        ? !(vm.searchFilters[0].model || vm.searchFilters[1].model) ||
          !vm.searchKey || !vm.searchKey.trim()
        : !vm.searchKey || !vm.searchKey.trim()
    }
  },
  methods: {
    searchData () {
      this.$store.dispatch('searchData', { origin: this.$route.name })
      this.searchKey = ''
      this.dialog = false
    }
  }
}
</script>
