<template>
  <div class="text-center container pt-15">
    <v-row>
      <v-col cols md="6" class="ma-auto">
        <h2 class="mt-5 mb-8">Add Album Manually</h2>
        <p class="font-italic" style="color:#e91e63">Note: Adding an album without an artist is disabled</p>
        <v-text-field v-model="albumName" outlined placeholder="Album Name" color="pink"/>
        <v-text-field v-model="artistName" outlined placeholder="Artist Name"  color="pink"/>
        <v-btn :disabled="disabled" :loading="loading" @click="addAlbum" rounded large color="pink">
          Save Album <v-icon right>mdi-content-save-outline</v-icon></v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AddManually',
  data: () => {
    return {
      artistName: null,
      albumName: null,
      loading: false
    }
  },
  computed: {
    disabled: {
      get: vm => (!vm.artistName || !vm.artistName.trim()) ||
        (!vm.albumName || !vm.albumName.trim())
    }
  },
  methods: {
    addAlbum () {
      this.loading = true

      this.$store.dispatch('addAlbum', {
        id: null,
        artistId: null,
        name: this.albumName,
        artistName: this.artistName,
        manual: true
      }).then(() => this.resetInputs())
    },
    resetInputs () {
      this.artistName = null
      this.albumName = null
      this.loading = false
    }
  }
}
</script>
