const artists = [
  {
    id: '70fac1fa-9ed1-43bc-92f0-0032ea140fe8',
    name: 'Pink Floyd'
  },
  {
    id: '3a82d368-afcb-4e54-b678-f1b0e0828703',
    name: 'King Crimson'
  },
  {
    id: '8542f5f4-ef70-43e9-9d58-99ec50b3ab6b',
    name: 'Sonic Youth'
  },
  {
    id: '4d2e0178-ac33-4c6c-a568-8214410c20b1',
    name: 'Yes'
  },
  {
    id: '984ee0e0-1f01-4855-853a-ebc3a372653d',
    name: 'John Frusciante'
  },
  {
    id: 'a452070e-1507-4a77-9373-e76085ad231c',
    name: 'Manogurgeil'
  },
  {
    id: '805aa824-bab2-487d-98f4-daede3e772cc',
    name: 'Butthole Surfers'
  }
]

const albums = [
  {
    id: '50ca839e-cfae-413d-a555-c287a4a0395e',
    artistId: '70fac1fa-9ed1-43bc-92f0-0032ea140fe8',
    name: 'Wish You Were Here'
  },
  {
    id: '807d4c2a-1593-482a-963e-02c25eed7423',
    artistId: '3a82d368-afcb-4e54-b678-f1b0e0828703',
    name: 'In the Court of the Crimson King'
  },
  {
    id: 'c356c8ff-56c8-4a5a-ab63-797251bdb7cf',
    artistId: '8542f5f4-ef70-43e9-9d58-99ec50b3ab6b',
    name: 'Sister'
  },
  {
    id: 'fe120781-1207-4b77-ad3b-e5a3b8cd1ba5',
    artistId: '8542f5f4-ef70-43e9-9d58-99ec50b3ab6b',
    name: 'Daydream Nation'
  },
  {
    id: '60225256-3309-435d-9da4-1858872bb5b2',
    artistId: '4d2e0178-ac33-4c6c-a568-8214410c20b1',
    name: 'Close to the Edge'
  },
  {
    id: '69fcea1a-d21b-4734-baa6-0285c44875fb',
    artistId: '984ee0e0-1f01-4855-853a-ebc3a372653d',
    name: 'Niandra LaDes and Usually Just a T-Shirt'
  },
  {
    id: 'a1291352-afd5-43ad-8552-6342dcd7be0b',
    artistId: 'a452070e-1507-4a77-9373-e76085ad231c',
    name: 'Unirytmejä'
  },
  {
    id: '03e217d9-d32b-4aba-82a2-c35f3ae6a56e',
    artistId: '805aa824-bab2-487d-98f4-daede3e772cc',
    name: 'Independent Worm Saloon'
  }
]

export { artists, albums }
