import { fromSeconds } from 'from-seconds'

const pagination = tracks => {
  return {
    paginationLength: Math.trunc(tracks / 4) + tracks % 4 ? 1 : 0,
    paginationPage: 1
  }
}

const formatDuration = duration => {
  const obj = fromSeconds(duration).toMinutes()

  return `${
    obj.minutes < 10 ? '0' + obj.minutes : obj.minutes
  }:${
    obj.seconds < 10 ? '0' + obj.seconds : obj.seconds
  }`
}

const trackIndex = tracks => {
  const l = tracks.length
  for (let i = 0; i < l; i++) {
    tracks[i].index = i + 1
  }
  return tracks
}

const coverImage = images => {
  const img = images.length
    ? images
      .find(image => image.size === 'mega' &&
        image['#text'].length) || require('@/assets/logo.png')
    : require('@/assets/logo.png')

  return typeof img === 'object'
    ? img['#text']
    : img
}

const toCommas = val => {
  return val
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export {
  formatDuration,
  pagination,
  coverImage,
  trackIndex,
  toCommas
}
