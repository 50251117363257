import { artists as mockArtists } from '../../../mockdata'
import { lastfm as LastFm } from '../../utils/last.fm'
import axios from 'axios'

const LAST_FM_API_KEY = 'd371ee54897f80b33e98a9d2fb6a4363'
const API_PATH = 'http://localhost:4000/api/artists'

const state = {
  artists: []
}

const getters = {
  artists: state => state.artists
}

const actions = {
  fetchArtists (context) {
    context.dispatch('clearSearchResults')
    context.dispatch('updateConnectivityProbe', true)
    context.dispatch('updateSkeletonLoader', { artist: true })

    return new Promise((resolve, reject) => {
      axios.get(API_PATH).then((response) => {
        const artists = []
        const lastfm = LastFm(LAST_FM_API_KEY)

        const shelfArtists = (response.data.length)
          ? response.data
          : mockArtists

        shelfArtists
          .forEach(artist => {
            if (artist.manual) {
              artists.unshift({
                image: require('@/assets/logo.png'),
                name: artist.name,
                manual: artist.manual,
                id: artist.id,
                listeners: 0,
                url: null
              })
            } else {
              lastfm.artistInfo({ artist: artist.name }, (error, data) => {
                if (error) {
                  if (error.message.includes('Network Error')) {
                    context.dispatch('updateSkeletonLoader', { artist: false })
                    context.dispatch('updateConnectivityProbe', false)
                    context.dispatch('showSnackbar', {
                      text: 'Please check your internet connection',
                      color: 'error'
                    })
                    context.commit('SET_ARTISTS', [])
                  } else context.dispatch('showSnackbar', { text: error.message, color: 'error' })
                } else {
                  Object.assign(data, { manual: artist.manual })
                  artists.unshift(data)
                }
              })
            }
          })

        context.commit('SET_ARTISTS', artists)
        setTimeout(() => context.dispatch('updateSkeletonLoader', { artist: false }), 1500)
        resolve(artists)
      },
      error => reject(error))
    })
  },

  addArtist (context, payload) {
    return new Promise((resolve, reject) => {
      const exists = context.getters.artists
        .filter(artist => artist.name === payload.name)
        .find(artist => artist.id === payload.id)
      if (exists) {
        resolve(exists)
        return
      }

      axios.post(API_PATH, payload).then((response) => {
        const lastfm = LastFm(LAST_FM_API_KEY)
        const artist = response.data

        if (artist.manual) {
          context.commit('CREATE_ARTIST', artist)
          resolve(artist)
        } else {
          lastfm.artistInfo({ artist: artist.name }, (error, data) => {
            if (error) context.dispatch('showSnackbar', { text: error.message, color: 'error' })
            else {
              context.commit('CREATE_ARTIST', data)
              resolve(artist)
            }
          })
        }
      },
      error => reject(error))
    })
  },

  async removeArtist (context, artistId) {
    await axios.delete(API_PATH, { data: { id: artistId } })
      .then(() => {
        context.commit('DELETE_ARTIST', artistId)
      })
      .catch(error => context.dispatch('showSnackbar', { text: error, color: 'error' }))
  }
}

const mutations = {
  SET_ARTISTS: (state, artists) => (state.artists = artists),
  CREATE_ARTIST: (state, data) => (state.artists.unshift(data)),
  DELETE_ARTIST: (state, artistId) => {
    const index = state.artists
      .map(artist => artist.id)
      .indexOf(artistId)
    state.artists.splice(index, 1)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
