<template>
  <div id="AddAlbum">
    <template v-if="$route.name.includes('AddLastFmAlbum')">
      <AlbumList v-if="searchResults.length"
      :albumOperations="albumOperations_"
      :headers="dataTableHeaders"
      :albums="searchResults" />
      <SkeletonLoader v-else :origin="origin" />
    </template>
    <AddManually v-else />
  </div>
</template>

<script>
import SkeletonLoader from '@/components/Misc/SkeletonLoaders/Albums'
import AddManually from '@/components/Albums/AddManually'
import AlbumList from '@/components/Albums/AlbumList'
import { mapGetters } from 'vuex'

export default {
  name: 'AddAlbum',
  components: {
    SkeletonLoader,
    AddManually,
    AlbumList
  },
  computed: {
    ...mapGetters(['searchResults', 'searchMode', 'dataTableHeaders', 'albumOperations']),
    origin: {
      get: vm => vm.searchMode.state ? 'lastfm-albums-search' : 'lastfm-default-search'
    },
    albumOperations_: {
      get: vm => [vm.albumOperations.find(e => vm.origin.includes(e.origin))]
    }
  }
}
</script>
