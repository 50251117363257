<template>
  <v-row id="ArtistList">
    <v-col v-for="(artist, key) in artists" :key="key"
      text-align="center" class="px-1" cols="12" lg="2" md="3" sm="4">
      <v-card dark flat link color="#0000" class="artists mx-auto"
      :href="artist.url" target="_blank">
        <v-card-text class="pa-0 ma-o">
          <v-list-item-avatar size="185" center color="grey">
            <img :src = artist.image alt="CoverImage">
          </v-list-item-avatar>
        </v-card-text>

        <v-card-title class="body-2 pt-0">
          {{ artist.name }}
          <v-chip v-if="artist.manual" color="primary" class="ml-2" x-small>manual</v-chip>
          </v-card-title>
        <v-card-subtitle class="caption pb-0">
          {{ `${artist.listeners} Listeners` }}
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ArtistList',
  props: ['artists']
}
</script>
