import { lastfm as LastFm } from '../../utils/last.fm'
import * as mockData from '../../../mockdata'
import axios from 'axios'

const LAST_FM_API_KEY = 'd371ee54897f80b33e98a9d2fb6a4363'
const API_PATH = 'http://localhost:4000/api/albums'

const state = {
  albums: [],
  dataTableHeaders: [
    { text: null, value: 'index', width: 5 },
    { text: 'Play', value: 'play', width: 5, sortable: false },
    { text: 'Title', value: 'name' },
    { text: 'Duration', value: 'duration', width: 10, align: 'end' }
  ],

  albumOperations: [
    {
      color: 'error',
      icon: 'mdi-delete-outline',
      handler: 'removeAlbum',
      text: 'Remove Album',
      origin: 'shelf'
    },
    {
      color: 'success',
      icon: 'mdi-plus',
      handler: 'addAlbum',
      text: 'Add Album',
      origin: 'search'
    }
  ]
}

const getters = {
  dataTableHeaders: state => state.dataTableHeaders,
  albumOperations: state => state.albumOperations,
  albums: state => state.albums
}

const actions = {
  async fetchAlbums (context, payload) {
    context.dispatch('clearSearchResults')
    context.dispatch('updateConnectivityProbe', true)
    if (payload.loader) context.dispatch('updateSkeletonLoader', { album: true })

    await axios.get(API_PATH)
      .then((response) => {
        const albums = []
        const lastfm = LastFm(LAST_FM_API_KEY)

        const _albums = (response.data.length)
          ? response.data
          : mockData.albums
            .map(album => {
              return {
                id: album.id,
                name: album.name,
                manual: false,
                artistId: mockData.artists
                  .find(artist => artist.id === album.artistId).id,
                artistName: mockData.artists
                  .find(artist => artist.id === album.artistId).name
              }
            })

        _albums
          .forEach(album => {
            const payload = {
              id: album.id,
              album: album.name,
              artistId: album.artistId,
              artist: album.artistName
            }

            if (album.manual) {
              albums.unshift({
                artist: {
                  name: album.artistName,
                  id: album.artistId,
                  url: null
                },
                image: require('@/assets/logo.png'),
                manual: album.manual,
                paginationLength: 1,
                paginationPage: 1,
                name: album.name,
                listeners: '0',
                id: album.id,
                tracks: []
              })
            } else {
              lastfm.albumInfo(payload, (error, data) => {
                if (error) {
                  if (error.message.includes('Network Error')) {
                    context.dispatch('updateSkeletonLoader', { album: false })
                    context.dispatch('updateConnectivityProbe', false)
                    context.dispatch('showSnackbar', {
                      text: 'Please check your internet connection',
                      color: 'error'
                    })
                    context.commit('SET_ALBUMS', [])
                  } else context.dispatch('showSnackbar', { text: error.message, color: 'error' })
                } else {
                  Object.assign(data, { manual: album.manual })
                  albums.unshift(data)
                }
              })
            }
          })

        context.commit('SET_ALBUMS', albums)
        setTimeout(() => context.dispatch('updateSkeletonLoader', { album: false }), 1500)
      }).catch(error => context.dispatch('showSnackbar', { text: error.message, color: 'error' }))
  },

  async addAlbum (context, payload) {
    const exists = context.getters.albums
      .filter(album => album.name === payload.name).length
    if (exists) {
      context.dispatch('showSnackbar', {
        text: 'This album already exists in your shelf',
        color: 'info'
      })
      return
    }

    return new Promise((resolve, reject) => {
      context.dispatch('addArtist', {
        id: payload.artistId,
        name: payload.artistName,
        manual: payload.manual
      }).then((res) => {
        const params = {
          id: payload.id || null,
          name: payload.name,
          artistId: res.id,
          manual: payload.manual
        }

        axios.post(API_PATH, params).then((response) => {
          const lastfm = LastFm(LAST_FM_API_KEY)
          const album = response.data

          if (album.manual) {
            const data = {
              artist: {
                name: album.artistName,
                id: album.artistId,
                url: null
              },
              image: require('@/assets/logo.png'),
              manual: album.manual,
              paginationLength: 1,
              paginationPage: 1,
              name: album.name,
              listeners: '0',
              id: album.id,
              tracks: []
            }
            context.commit('CREATE_ALBUM', data)
            context.dispatch('showSnackbar', {
              text: 'Album successfully added to shelf',
              color: 'success'
            })
            resolve(data)
          } else {
            const albumParams = {
              id: album.id,
              album: album.name,
              artist: res.name
            }

            lastfm.albumInfo(albumParams, (error, albumInfo) => {
              if (error) context.dispatch('showSnackbar', { text: error.message, color: 'error' })
              else {
                context.commit('CREATE_ALBUM', albumInfo)
                context.dispatch('showSnackbar', {
                  text: 'Album successfully added to shelf',
                  color: 'success'
                })
                resolve(albumInfo)
              }
            })
          }
        },
        error => reject(error))
      }).catch(error => reject(error))
    })
  },

  async removeAlbum (context, payload) {
    const exists = context.getters.albums
      .filter(album => album.artist.id === payload.artistId).length

    await axios.delete(API_PATH, { data: { id: payload.albumId } })
      .then(() => {
        context.commit('DELETE_ALBUM', {
          albumId: payload.albumId,
          searchResults: context.getters.searchResults
        })

        if (exists === 1) context.dispatch('removeArtist', payload.artistId)

        context.dispatch('showSnackbar', {
          text: 'Album successfully removed from shelf',
          color: 'error'
        })
      })
      .catch((error) => context.dispatch('showSnackbar', { text: error.message, color: 'error' }))
  }
}

const mutations = {
  SET_ALBUMS: (state, albums) => (state.albums = albums),
  CREATE_ALBUM: (state, data) => (state.albums.unshift(data)),
  DELETE_ALBUM: (state, data) => {
    const shelfIndex = state.albums.map(e => e.id).indexOf(data.albumId)
    state.albums.splice(shelfIndex, 1)

    const searchIndex = data.searchResults.map(e => e.id).indexOf(data.albumId)
    data.searchResults.splice(searchIndex, 1)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
