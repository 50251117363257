<template>
  <v-app id="muziqa">
    <Navigation />
    <v-main>
      <v-container><router-view/></v-container>
    </v-main>
    <Footer :snackbar="snackbar" />
    <ConfirmationModal />
  </v-app>
</template>

<script>
import ConfirmationModal from '@/components/Misc/ConfirmationModal'
import Navigation from '@/components/Misc/Navigation'
import Footer from '@/components/Misc/Footer'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    ConfirmationModal,
    Navigation,
    Footer
  },
  computed: {
    ...mapGetters(['snackbar'])
  }
}
</script>
