<template>
  <v-footer id="Footer" fixed dense app>
    <v-spacer></v-spacer>
    <span class="caption">
      &copy; {{ new Date().getFullYear() }}
      &nbsp;Muziqa | Powered by
      <a href="https://codeshares.io" target="_blank">Codeshares</a>
    </span>
    <v-spacer></v-spacer>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="3500">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="$store.dispatch('closeSnackbar')">Close</v-btn>
      </template>
    </v-snackbar>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  props: ['snackbar']
}
</script>
