<template>
  <div id="Home">
    <AlbumList v-if="connectivityProbe && albums_.length"
      :albumOperations="albumOperations_"
      :headers="dataTableHeaders"
      :albums="albums_" />
    <SkeletonLoader v-else :origin="origin" :connectivityProbe="connectivityProbe" />
  </div>
</template>

<script>
import SkeletonLoader from '@/components/Misc/SkeletonLoaders/Albums'
import AlbumList from '@/components/Albums/AlbumList'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    SkeletonLoader,
    AlbumList
  },
  computed: {
    ...mapGetters([
      'connectivityProbe',
      'dataTableHeaders',
      'albumOperations',
      'searchResults',
      'searchMode',
      'albums']),
    origin: {
      get: vm => vm.searchMode.state ? 'shelf-albums-search' : 'shelf-albums'
    },
    albums_: {
      get: vm => vm.searchMode.state &&
      ('Home'.includes(vm.$route.name) || 'AddLastFmAlbum'.includes(vm.$route.name))
        ? vm.searchResults
        : vm.albums
    },
    albumOperations_: {
      get: vm => [vm.albumOperations.find(e => vm.origin.includes(e.origin))]
    }
  }
}
</script>
