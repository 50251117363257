const state = {
  snackbar: {
    show: false,
    color: null,
    text: null
  },
  skeletonLoading: {
    artist: false,
    album: false
  },
  confirmModal: {
    identifier: null,
    operation: null,
    state: false,
    title: null,
    info: null
  },
  connectivityProbe: true
}

const getters = {
  connectivityProbe: state => state.connectivityProbe,
  skeletonLoading: state => state.skeletonLoading,
  confirmModal: state => state.confirmModal,
  snackbar: state => state.snackbar
}

const actions = {
  closeSnackbar: (context) => context.commit('HIDE_SNACKBAR', { show: false, text: null, color: null }),
  updateSkeletonLoader: (context, payload) => context.commit('UPDATE_SKELETON_LOADER', payload),
  updateConnectivityProbe: (context, payload) => context.commit('SET_CONNECTIVITY_PROBE', payload),
  updateConfirmModal: (context, payload) => context.commit('SET_CONFIRM_MODAL', payload),
  showSnackbar: (context, payload) => context.commit('SET_SNACKBAR', payload)
}

const mutations = {
  SET_CONNECTIVITY_PROBE: (state, bool) => (state.connectivityProbe = bool),
  UPDATE_SKELETON_LOADER: (state, data) => Object.assign(state.skeletonLoading, data),
  SET_CONFIRM_MODAL: (state, data) => Object.assign(state.confirmModal, data),
  HIDE_SNACKBAR: (state, data) => (state.snackbar = data),
  SET_SNACKBAR: (state, snackbar) => {
    state.snackbar.show = true
    Object.assign(state.snackbar, snackbar)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
