<template>
  <v-row v-if=" connectivityProbe && skeletonLoading.album">
    <v-col v-for="n in 4" :key="n" cols="12" md="6">
      <v-skeleton-loader
        class="albums-loader"
        v-bind="attrs"
        type="list-item-avatar-three-line, divider, table-row-divider@4">
      </v-skeleton-loader>
    </v-col>
  </v-row>
  <ErrorPage v-else :origin="origin_" />
</template>

<script>
import ErrorPage from '@/components/Misc/ErrorPage'
import { mapGetters } from 'vuex'

export default {
  props: ['origin'],
  name: 'AlbumsLoader',
  components: {
    ErrorPage
  },
  data: () => {
    return {
      attrs: {
        class: 'mb-5 mx-10',
        boilerplate: false
      }
    }
  },
  computed: {
    ...mapGetters(['skeletonLoading', 'connectivityProbe']),
    origin_: {
      get: vm => vm.connectivityProbe ? vm.origin : 'connectivity'
    }
  }
}
</script>
