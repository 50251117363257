<template>
  <v-row>
    <v-col cols="6" md="6">
      <img style="height: 620px" class="img" alt="Not Found" :src = bgImg>
    </v-col>
    <v-col cols="6" md="6" class="align-self-center">
      <div style="color:#e91e63" class="display-4 mb-3">{{ alert }}</div>
      <div class="mb-1" v-html="message"></div>
   </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ErrorPage',
  props: ['origin'],
  computed: {
    alert: {
      get: vm => vm.origin.includes('default') ? 'Search' : 'Oops!'
    },
    module: {
      get: vm => vm.origin.includes('albums') ? 'Albums' : 'Artists'
    },
    bgImg: {
      get: vm => vm.origin.includes('default')
        ? require('@/assets/search.png')
        : vm.origin.includes('connectivity')
          ? require('@/assets/no-internet.png')
          : require('@/assets/not-found.png')
    },
    message: {
      get: vm => vm.origin.includes('default')
        ? `Use the searchbox to search your favourite albums from${null}
          <b style="color:#e91e63">Last.Fm</b><br />You can add the albums you like to your shelf 😉`
        : vm.origin.includes('search')

          ? `Your search returned nothing,
            ${vm.origin.includes('shelf-albums')
              ? 'please review your filters or search something else'
              : 'please search something else'}`
          : vm.origin.includes('connectivity')

            ? 'Connectivity lost.<br />Please check your internet connection and try again'
            : `No ${vm.module.toLowerCase()} found in your shelf, please add new
             ${vm.module.toLowerCase()} to your shelf or go to <b style="color:#e91e63">Shelf &rarr; 
             ${vm.module}</b> to load mock data`
    }
  }
}
</script>
