import artists from './artists'
import search from './search'
import albums from './albums'
import app from './app'

export default {
  artists,
  search,
  albums,
  app
}
