<template>
  <div id="TrackPlayer">
    <v-bottom-sheet inset v-model="track.playing">
      <v-card tile >
        <v-progress-linear :value="39" class="my-0" color="pink" height="3"></v-progress-linear>
        <v-list color="black">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ track.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ track.artist.name }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer></v-spacer>
            <v-list-item-icon>
              <v-btn disabled icon><v-icon>mdi-rewind</v-icon></v-btn>
            </v-list-item-icon>
            <v-list-item-icon :class="{ 'mx-5': $vuetify.breakpoint.mdAndUp }">
              <v-btn icon link :href="track.url" target="_blank"><v-icon x-large color="pink">mdi-play-circle</v-icon></v-btn>
            </v-list-item-icon>
            <v-list-item-icon class="ml-0" :class="{ 'mr-3': $vuetify.breakpoint.mdAndUp }">
              <v-btn disabled icon><v-icon>mdi-fast-forward</v-icon></v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: 'TrackPlayer',
  props: ['track']
}
</script>
