<template>
  <div>
    <v-app-bar app color="black" style="z-index: 10;" flat clipped-right dark>
      <v-container class="py-0 fill-height">
        <router-link to="/"  @click.native="init" class="d-flex align-center" style="color:#0000">
          <v-avatar class="mr-2" color="logo grey darken-1" size="48"><img :src = logo alt="logo"></v-avatar>
          <v-toolbar-title class="display-1" style="color:#e91e63">MUZIQA</v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>
        <Search v-if="!$route.name.includes('AddManualAlbum')" />
        <v-spacer></v-spacer>
        <div class="desktop-nav">
          <v-menu offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-chip outlined color="pink" text-color="pink" class="ma-2" v-bind="attrs" v-on="on">
                <v-avatar left class="pink darken-4"><span class="span">{{ shelfCount }}</span></v-avatar>
                SHELF
              </v-chip>
            </template>
            <v-list>
              <v-list-item router to="/" @click="init"><v-list-item-title>Albums</v-list-item-title></v-list-item>
              <v-list-item router to="/artists" @click="init"><v-list-item-title>Artists</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-chip outlined color="pink" text-color="pink" class="ma-2 mr-0" v-bind="attrs" v-on="on">
                ADD NEW ALBUM
              </v-chip>
            </template>
            <v-list>
              <v-list-item router to="/add-lastfm-album" @click="init">
                <v-list-item-title>Last.Fm</v-list-item-title>
              </v-list-item>
              <v-list-item router to="/add-manual-album"><v-list-item-title>Manual</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-app-bar-nav-icon x-large @click="drawer = !drawer" color="pink" class="nav-humbuger"></v-app-bar-nav-icon>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer app clipped right v-model="drawer" temporary fixed mobile-breakpoint="0" class="mobile-nav">
      <div class="head"></div>
      <v-list>
      <v-list-item-group color="pink">
        <v-list-item router to="/" @click="init">
          <v-list-item-title>Shelf Albums</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/artists" @click="init">
          <v-list-item-title>Shelf Artists</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/add-lastfm-album" @click="init">
          <v-list-item-title>Add Last.Fm Album</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/add-manual-album">
          <v-list-item-title>Add Manual Album</v-list-item-title>
        </v-list-item>
      </v-list-item-group>

    </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Search from '@/components/Misc/Search'
import { mapGetters } from 'vuex'
export default {
  name: 'Navigation',
  components: {
    Search
  },
  data: () => {
    return {
      drawer: null,
      logo: require('@/assets/logo.png'),
      title: 'Record Shelf'
    }
  },
  computed: {
    ...mapGetters(['albums', 'artists']),
    shelfCount: {
      get: vm => vm.$route.name.includes('Home') || vm.$route.name.includes('Album')
        ? vm.albums.length
        : vm.artists.length
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    '$route.path': {
      handler (val) {
        this.init()
      }
    }
  },
  methods: {
    init () {
      this.$store.dispatch('updateSearchMode', { state: false, origin: null })
      this.$store.dispatch('updateSearchQuery', { key: '' })
      this.$store.dispatch('clearSearchResults')

      if ('AddLastFmAlbum'.includes(this.$route.name)) {
        this.$store.dispatch('updateSearchBarVisibility', false)
        this.$store.dispatch('fetchAlbums', { loader: false })
      } else if ('Artists'.includes(this.$route.name)) this.$store.dispatch('fetchArtists')
      else this.$store.dispatch('fetchAlbums', { loader: true })
    }
  }
}
</script>
