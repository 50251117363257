import * as utils from '../utils'
import axios from 'axios'

class LastFM {
  constructor (LAST_FM_API_KEY) {
    this.API_PATH = 'https://ws.audioscrobbler.com/2.0/?'
    this.apiKey = LAST_FM_API_KEY
  }

  albumSearch (options, callback) {
    Object.assign(options, {
      method: 'album.search'
    })

    this.fetchData(options, 'results', (error, results) => error
      ? callback(error)
      : callback(null, getAlbumInfo(this, results.albummatches.album)))

    const getAlbumInfo = (vm, results) => {
      const albums = []
      results.forEach(res => {
        const payload = {
          album: res.name,
          artist: res.artist
        }

        vm.albumInfo(payload, (error, data) => {
          if (!error) albums.unshift(data)
        })
      })
      return albums
    }
  }

  albumInfo (options, callback) {
    Object.assign(options, {
      method: 'album.getinfo',
      autocorrect: 1
    })

    this.fetchData(options, 'album', (error, album) => error
      ? callback(error)
      : callback(null, this.formatAlbum(album, options.id, options.artistId)))
  }

  formatAlbum (album, albumID, artistID) {
    delete album.playcount
    delete album.wiki
    delete album.tags

    Object.assign(album, {
      tracks: this.formatTracks(album.tracks.track),
      image: utils.coverImage(album.image),
      listeners: utils.toCommas(album.listeners),
      id: albumID || album.mbid
    },
    utils.pagination(album.tracks.track.length))

    const artist = album.tracks.length
      ? album.tracks.find(track => track.artist.id && track.artist.name === album.artist).artist
      : { id: artistID || null, name: album.artist, url: null }

    Object.assign(artist, { id: artistID || artist.id })
    Object.assign(album, { artist: artist })

    delete album.mbid
    delete album.url

    return album
  }

  formatTracks (tracks) {
    const tracks_ = tracks
      .map(track => {
        const tracks__ = {
          url: track.url,
          playing: false,
          name: track.name,
          artist: Object.assign(track.artist, {
            id: track.artist.mbid
          }),
          duration: utils.formatDuration(track.duration)
        }

        delete track.artist.mbid
        return tracks__
      })

    return utils.trackIndex(tracks_)
  }

  formatArtist (artist, artistID) {
    delete artist.streamable
    delete artist.similar
    delete artist.ontour
    delete artist.tags
    delete artist.bio

    Object.assign(artist, {
      id: artistID || artist.mbid,
      image: utils.coverImage(artist.image),
      listeners: utils.toCommas(artist.stats.listeners)
    })

    delete artist.mbid
    delete artist.stats

    return artist
  }

  artistInfo (options, callback) {
    Object.assign(options, {
      method: 'artist.getinfo',
      autocorrect: 1
    })

    this.fetchData(options, 'artist', (error, artist) => error
      ? callback(error)
      : callback(null, this.formatArtist(artist, options.id)))
  }

  fetchData (params, name, callback) {
    const querystring = require('querystring')

    Object.assign(params, {
      limit: params.limit || 5,
      api_key: this.apiKey,
      format: 'json'
    })

    const API_PATH = `${this.API_PATH}${querystring.stringify(params)}`

    axios.post(API_PATH)
      .then(response => callback(null, response.data[name]))
      .catch(error => callback(error))
  }
}

export const lastfm = (container, options) => new LastFM(container, options)
