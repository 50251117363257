import { lastfm as LastFm } from '../../utils/last.fm'

const LAST_FM_API_KEY = 'd371ee54897f80b33e98a9d2fb6a4363'

const state = {
  searchFilters: [
    { model: false, label: 'Include Artists' },
    { model: true, label: 'Include Albums' }
  ],
  searchBarVisibility: true,
  searchResults: [],
  searchQuery: '',
  searchMode: {
    state: false,
    origin: null
  }
}

const getters = {
  searchBarVisibility: state => state.searchBarVisibility,
  searchFilters: state => state.searchFilters,
  searchResults: state => state.searchResults,
  searchMode: state => state.searchMode,
  searchQuery: state => state.searchQuery
}

const actions = {
  searchLastFmAlbums (context, key) {
    return new Promise(function (resolve, reject) {
      const lastfm = LastFm(LAST_FM_API_KEY)

      lastfm.albumSearch({ album: key, limit: 30 }, (error, data) => {
        if (error) {
          if (error.message.includes('Network Error')) {
            context.dispatch('updateSkeletonLoader', { album: false })
            context.dispatch('updateConnectivityProbe', false)
            context.dispatch('showSnackbar', {
              text: 'Please check your internet connection',
              color: 'error'
            })
            context.commit('SET_ALBUMS', [])
          } else context.dispatch('showSnackbar', { text: error.message, color: 'error' })
        } else {
          context.commit('SET_SEARCH_RESULTS', data)
          resolve({ album: false })
        }
      })
    })
  },

  searchShelfAlbums (context, key) {
    return new Promise(function (resolve, reject) {
      const includeArtist = context.getters.searchFilters[0].model
      const includeAlbum = context.getters.searchFilters[1].model

      const results = context.getters.albums.filter(album => {
        const artistName = album.artist.name.toLowerCase()
        const albumName = album.name.toLowerCase()

        return (includeAlbum && includeArtist)
          ? albumName.includes(key) || artistName.includes(key)
          : includeAlbum
            ? albumName.includes(key)
            : includeArtist
              ? artistName.includes(key)
              : Error('Please enter a search term!')
      })

      context.commit('SET_SEARCH_RESULTS', results)
      resolve({ album: false })
    })
  },

  async searchShelfArtists (context, key) {
    return new Promise(function (resolve, reject) {
      const results = context.getters.artists
        .filter(artist => artist.name.toLowerCase().includes(key))

      context.commit('SET_SEARCH_RESULTS', results)
      resolve({ artist: false })
    })
  },

  searchData ({ dispatch, getters }, payload) {
    dispatch('updateSearchMode', { state: true, origin: payload.origin })
    dispatch('updateConnectivityProbe', true)
    dispatch('clearSearchResults')
    dispatch('updateSkeletonLoader',
      payload.origin.includes('Artist')
        ? { artist: true }
        : { album: true })

    if (payload.origin === 'AddLastFmAlbum') {
      dispatch('searchLastFmAlbums', getters.searchQuery.toLowerCase())
        .then(res => dispatch('updateSkeletonLoader', res))
    } else if (payload.origin === 'Home') {
      dispatch('searchShelfAlbums', getters.searchQuery.toLowerCase())
        .then(res => dispatch('updateSkeletonLoader', res))
    } else if (payload.origin === 'Artists') {
      dispatch('searchShelfArtists', getters.searchQuery.toLowerCase())
        .then(res => dispatch('updateSkeletonLoader', res))
    } else {
      dispatch('updateSkeletonLoader', { artist: false, album: false })
      return Error('Please enter a search term!')
    }
  },

  updateSearchBarVisibility: (context, payload) => context.commit('SET_SEARCH_BAR_VISIBILITY', payload),
  updateSearchQuery: (context, payload) => context.commit('SET_SEARCH_QUERY', payload.key),
  updateSearchMode: (context, payload) => context.commit('UPDATE_SEARCH_MODE', payload),
  clearSearchResults: (context) => context.commit('CLEAR_SEARCH_RESULTS')
}

const mutations = {
  SET_SEARCH_BAR_VISIBILITY: (state, bool) => (state.searchBarVisibility = bool),
  UPDATE_SEARCH_MODE: (state, data) => Object.assign(state.searchMode, data),
  SET_SEARCH_RESULTS: (state, data) => (state.searchResults = data),
  SET_SEARCH_QUERY: (state, query) => (state.searchQuery = query),
  CLEAR_SEARCH_RESULTS: (state) => (state.searchResults = [])
}

export default {
  state,
  getters,
  actions,
  mutations
}
