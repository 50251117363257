import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Artists from '../views/Artists'
import AddAlbum from '../views/AddAlbum'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/artists',
    name: 'Artists',
    component: Artists
  }, {
    path: '/add-lastfm-album',
    name: 'AddLastFmAlbum',
    component: AddAlbum
  }, {
    path: '/add-manual-album',
    name: 'AddManualAlbum',
    component: AddAlbum
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
